@import '../../styles/common.scss';

.container {
  display: flex;
  flex-direction: column;

  &.absoluteFill {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    > .header {
      padding-right: 8px;
    }
    > .body {
      @include scrollbar-default;

      overflow-x: hidden;
      overflow-y: scroll;
    }
  }
  &.thin {
    > .header,
    > .body > .row {
      min-height: 28px;
    }
    .column {
      font-size: 12px;
    }
  }
  > .header {
    display: flex;
    flex-direction: row;
    min-height: 40px;
    background-color: lighten($dark-color, 10%);
    color: white;

    > .column {
      border-color: rgba($line-color, 0.2);
    }
  }
  > .body {
    > .row {
      display: flex;
      flex-direction: row;
      min-height: 40px;
      border-bottom: 1px solid $line-color;

      &:hover {
        background-color: rgba($line-color, 0.2);
      }
      > .column {
        border-color: $line-color;
      }
    }
  }
  .column {
    display: flex;
    align-items: center;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    border-style: solid;
    border-width: 0 1px 0 0;

    &:last-child {
      border-right: none;
    }
    &.hidden {
      display: none;
    }
    &.center {
      justify-content: center;
      text-align: center;
    }
    &.left {
      padding-left: 12px;
    }
    &.right {
      padding-right: 12px;
      justify-content: flex-end;
      text-align: right;
    }
    &.check {
      width: 48px;
      justify-content: center;
      text-align: center;
    }
    &.pointer {
      cursor: pointer;
    }
  }
}
