@import '../../styles/common.scss';

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: $sidebar-width;
  background-color: lighten($dark-color, 10%);
  overflow: auto;
  z-index: 100;

  > .wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;

    > .logoWrapper {
      min-height: 112px;
      display: flex;
      align-items: center;
      justify-content: center;

      > .logo {
        width: calc(192px * 0.32);
        height: calc(192px * 0.32);
        background-image: url('../../assets/images/logo.png');
        background-size: cover;
        cursor: pointer;
      }
    }
    > .menusWrapper {
      flex: 1;
      border-top: 1px solid rgba($line-color, 0.1);
      padding-bottom: 52px;

      > .menuGroup {
        border-bottom: 1px solid rgba($line-color, 0.1);

        > .groupHeader {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          height: 48px;
          padding: 0 16px 0 20px;
          cursor: pointer;

          > .title {
            color: white;
            font-size: 14px;
          }
        }
        > .submenusWrapper {
          background-color: $dark-color;

          > .submenu {
            display: flex;
            align-items: center;
            padding-left: 32px;
            color: white;
            height: 40px;
            font-size: 13px;
            cursor: pointer;

            &.active {
              color: lighten($tint-color, 20%);
            }
          }
        }
      }
    }
    > .bottomMenu {
      display: flex;
      align-items: center;
      padding-left: 24px;
      color: gray;
      min-height: 52px;
      font-size: 14px;
      cursor: pointer;
      border-top: 1px solid rgba($line-color, 0.1);
    }
  }
}
