@import '../../styles/common.scss';

.container {
  position: absolute;
  width: 800px;
  z-index: 100;
  border: 1px solid $border-color;
  overflow: hidden;

  > .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    background-color: lighten($dark-color, 3%);

    > .title {
      flex: 1;
      font-size: 14px;
      color: white;
    }
    > .close {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
  > .body {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 400px;
    margin-right: -1px;
    background-color: white;
  }
}
