@import '../../styles/common.scss';

.button {
  font-size: 13px;
  height: 28px;
  padding: 0 16px 2px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: $tint-color;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: lighten($tint-color, 5%);
  }
}
