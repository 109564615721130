@import '../../styles/common.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;

  > .text {
    font-size: 14px;
    color: gray;
    margin-left: 4px;
    margin-bottom: 2px;
  }
}
