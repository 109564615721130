@import '../../styles/common.scss';

.container {
  @include scrollbar-default;

  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
}
