@import '../../../styles/common.scss';

.container {
  flex: 1;
  margin: 0 -12px;

  > .tableControl {
    margin: 8px 0 8px 12px;
    display: flex;
    flex-direction: row;
    align-items: center;

    > .button {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $border-color;
      border-radius: 2px;
      height: 32px;
      cursor: pointer;

      &.icon {
        width: 32px;
      }
    }
    > .panelWrapper {
      position: relative;
      margin: -32px 4px 0 8px;
    }
  }
  > .tableWrapper {
    position: relative;
    margin-bottom: -1px;
  }
}
.blue {
  color: blue;
}
