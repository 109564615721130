@import './common.scss';

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .select {
    font-size: 13px;
    height: 28px;
    padding: 0 8px 2px;
    border-radius: 4px;
  }
}
