@import './common.scss';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  @media print {
    visibility: hidden;
  }
}
body {
  font-family: 'Noto Sans KR', sans-serif;
}
input,
select,
button,
textarea {
  font-family: inherit;
}
select {
  border: 1px solid $border-color;
  border-radius: 2px;

  &:focus {
    outline: none;
  }
}
textarea {
  vertical-align: middle;
}
