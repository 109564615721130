@import '../../../styles/common.scss';

.container {
  flex: 1;
  display: flex;
  flex-direction: column;

  > .columns {
    flex: 1;
    display: flex;
    flex-direction: row;

    > .column {
      flex: 1;

      > .padding {
        padding: 8px;
      }
      .arrows {
        display: flex;
        flex-direction: row;

        > * {
          cursor: pointer;
        }
      }
      .tableField {
        height: 28px;
        text-align: center;
        padding: initial;
      }
      .textarea {
        resize: none;
        font-size: 12px;
        width: 100%;
        height: 160px;
        padding: 4px 0 4px 8px;
        border: 1px solid $border-color;
        border-radius: 2px;

        &:focus {
          outline-color: $tint-color;
        }
      }
    }
    > .center {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 56px;
      border-left: 1px solid $line-color;
      border-right: 1px solid $line-color;

      > .button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 8px;
        background-color: $dark-color;
      }
    }
  }
}
