@import '../../styles/form.module.scss';

.messageContainer {
  flex: 1;
  display: flex;
  flex-direction: row;

  > .column {
    flex: 1;

    .tableField {
      height: 28px;
    }
  }
  > .center {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    border-left: 1px solid $line-color;
    border-right: 1px solid $line-color;

    > .button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 8px;
      background-color: $dark-color;
      cursor: pointer;

      &:hover {
        background-color: lighten($dark-color, 5%);
      }
    }
  }
}
